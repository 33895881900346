import { LitElement, html, css } from "lit";

class ProteksiLanding extends LitElement {

  static styles = css`
      .section-proteksi-landing{
        display: flex;
        justify-content: center;
      }

      .container-proteksi-landing {
        text-align: center;
        margin: 64px 10% 10px;
        width: 1440px;
      }

      .proteksi-landing-title {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        letter-spacing: normal;
        line-height: normal;
        font-style: normal;
        font-stretch: normal;
        color: #424242;
      }

      .container-proteksi-landing p, .container-proteksi-landing ul li {
        text-align: left;
        color: #424242;
      }

      .proteksi-landing-span {
        color: #3570d2;
      }
      `;

    render(){
        return html`
        <div class="section-proteksi-landing">
            <div class="container-proteksi-landing">
                <h2 class="proteksi-landing-title">
                Memahami Apa Itu Asuransi Kesehatan
                </h2>
                <p>
                <strong>
                Ada beragam keuntungan dari penggunaan asuransi kesehatan. Salah satunya adalah Anda tak perlu terlalu pusing memikirkan biaya perawatan dan pengobatan yang dikeluarkan ketika sedang sakit, karena “kesehatan” Anda telah diasuransikan. Tentu hal ini dapat meringankan beban, sehingga Anda dapat menjalani pengobatan dengan lebih fokus dan tenang. 
                </strong>
                </p>
                <p>
                Asuransi kesehatan adalah jenis produk asuransi yang dapat menjamin kesehatan Anda saat sakit maupun mengalami kecelakaan. Asuransi kesehatan penting untuk dimiliki karena ada sejumlah manfaat yang bisa diperoleh dari ragam program asuransi yang ada. 
                </p>
                <p>
                <strong>Apa Saja Manfaat Asuransi Kesehatan?</strong><br />
                Berbagai manfaat yang bisa diperoleh dari asuransi kesehatan, di antaranya adalah:
                <ul>
                    <li>Memberikan perlindungan kesehatan dan menanggung biaya pemeriksaan kesehatan terhadap penyakit tertentu yang diderita</li>
                    <li>Menanggung biaya obat dan konsultasi dokter saat melakukan rawat jalan</li>
                    <li>Memberikan perlindungan kesehatan dan menanggung biaya rawat inap, pengobatan, dan biaya dokter selama dirawat di rumah sakit</li>
                    <li>Menanggung biaya operasi penyakit tertentu</li>
                    <li>Menanggung atau mengurangi biaya persalinan</li>
                    <li>Menanggung biaya kecelakaan atau cedera karena kecelakaan</li>
                    <li>Menanggung biaya pemeriksaan kesehatan secara menyeluruh, seperti <em>medical check up</em>, setiap tahunnya</li>
                </ul>
                </p>
                <p>
                <strong>Apa Saja Jenis Asuransi Kesehatan?</strong><br />
                Manfaat asuransi kesehatan yang dapat diperoleh tergantung pada jenis asuransi kesehatan yang diambil, termasuk premi yang dibayarkan setiap bulannya.
                <br /><br />
                Ada asuransi kesehatan yang menanggung biaya pengobatan secara menyeluruh, ada pula yang menanggung sebagian dari biaya perawatan dan pengobatan yang diterima. Anda bisa memilih sesuai dengan dana dan kebutuhan Anda.
                </p>
                <p>
                <strong>Apa Itu Aloproteksi?</strong><br />
                Di Indonesia, terdapat beragam asuransi kesehatan, dan <strong> Aloproteksi </strong> merupakan salah satu asuransi kesehatan terbaik sebagai produk dari super app kesehatan no.1 di Indonesia, Alodokter.
                <br /><br />

                <strong>Aloproteksi</strong> memiliki 3 pilihan program perlindungan kesehatan terlengkap, yaitu <strong>Aloproteksi Chat Dokter, Aloproteksi,</strong> dan <strong>Aloproteksi Plus</strong><br /><br />

                Ketiga program ini masing-masing memiliki manfaat unlimited chat untuk berkonsultasi dengan semua dokter umum, spesialis, dan ahli kesehatan seperti psikolog via aplikasi Alodokter. Kapan pun dan di mana pun! Dengan lebih dari 1200 dokter dan ahli kesehatan andal yang siap memberikan solusi untuk pertanyaan kesehatan Anda.<br /><br />

                Selain manfaat unlimited chat, pemilik <strong>Aloproteksi</strong> akan mendapatkan manfaat asuransi kesehatan berupa rawat jalan, termasuk pemeriksaan laboratorium serta pemberian obat-obatan, dan untuk pemilik <strong>Aloproteksi Plus</strong> juga akan mendapatkan tambahan manfaat rawat inap yang komprehensif sesuai syarat dan ketentuan Aloproteksi.<br /><br />

                Pengajuan klaim cukup isi data dan <em>upload</em> dokumen yang dibutuhkan via aplikasi. Semua keunggulan <strong>Aloproteksi</strong> bisa Anda nikmati dengan membayar biaya berlangganan yang terjangkau, yakni antara  Rp125.000−Rp250.000.<br /><br />
                Dengan <strong>Aloproteksi</strong>,  Anda akan mendapatkan saran kesehatan yang nyata dari konsultasi dengan dokter andal yang dapat dilakukan kapan dan di mana saja, dan tidak ada batasnya. Suatu <em>privilege</em> yang dapat Anda nikmati kemudahannya, layanan kesehatan lengkap dalam satu genggaman.<br /><br />

                Itulah informasi seputar asuransi kesehatan yang perlu Anda ketahui. Memilih asuransi kesehatan yang tepat memang bukan perkara mudah. Anda harus mempertimbangkan manfaatnya, termasuk biaya yang harus dikeluarkan dan keuntungan apa saja yang bisa diperoleh dari program asuransi kesehatan yang Anda pilih.
                </p>
            </div>
            </div>
        `
    }

}

customElements.define('proteksi-landing', ProteksiLanding);